// Shopping cart hook
import { Box, Typography, styled } from '@mui/material';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cart, CartItem, Product, ProductCollectionItem } from '../../../../types/operations';
import ConfirmDialog from '../../../app/confirmation-dialog';
import { useAuth } from '../../../auth/auth-provider';
import { useProductValidator } from '../../products/hooks/use-product-validator';
import { useDeleteCart } from '../api/delete-cart';
import { useDeleteCartItems } from '../api/delete-cart-items';
import { useFetchCart } from '../api/fetch-cart';
import { useUpdateCartItems } from '../api/update-cart-items';
import { CartItemDescription } from '../components/cart-item-description';
import { ShoppingCart } from '../shopping-cart';

// Will Allow an optional initial prop for testing.
type ShoppinCartProviderProps = React.PropsWithChildren<{
  onOpen?: () => void;
  onItemAdded?: (item: CartItem) => void;
  onItemRemoved?: (item: CartItem) => void;
  onItemUpdated?: (item: CartItem) => void;
  onEmptyCart?: () => void;
  initialOpen?: boolean;
}>;

interface ShoppingCartContextState {
  shoppingCartOpen: boolean;
  setShoppingCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cart: Cart | undefined;
  addCartItem: (
    product: Product | ProductCollectionItem,
    quantity: number,
    note?: string,
    isMissingProductRequest?: boolean
  ) => void;
  updateCartItem: (id: string, product: Product | ProductCollectionItem, quantity: number, note?: string) => void;
  removeCartItems: (ids: string[]) => void;
  confirmOpen: boolean;
  setConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEmpty: boolean;
  isLoading: boolean;
  isCompleted: boolean;
  emptyCart: (options?: { withoutConfirmation?: boolean }) => Promise<void>;
  error: Error | undefined;

  updateRequiredItemsList: (value: object) => void;

  updateRequiredItems: (value: string, itemKey: string) => void;

  requiredItems: object | undefined;
}

export const ShoppingCartContext = createContext<ShoppingCartContextState | null>(null);

export const ShoppingCartProvider: React.FC<ShoppinCartProviderProps> = ({
  children,
  onOpen,
  onItemAdded,
  onItemUpdated,
  onItemRemoved,
  onEmptyCart,
  initialOpen = false,
}) => {
  const [shoppingCartOpen, setShoppingCartOpen] = useState(initialOpen);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [cart, setCart] = useState<Cart | undefined>();
  const [fetch, fetchCart] = useFetchCart();
  const [update, updateCartItems] = useUpdateCartItems();
  const [remove, deleteCartItems] = useDeleteCartItems();
  const [deleting] = useDeleteCart();
  const { t } = useTranslation();

  // These are required product specifications items that include port size, control unit, etc.
  // The aim is to keep track of which item has been filled. Once all the required product specifications items have a value, then we will
  // enable cart item
  const [requiredItems, setRequiredItems] = useState<object | undefined>();

  const isLoading = fetch.loading || update.loading || remove.loading || deleting.loading;
  const error = fetch.error || update.error || remove.error || deleting.error;
  const isCompleted = fetch.isCompleted;
  const [itemsToDelete, setItemsToDelete] = useState<CartItem[]>([]);
  const { user } = useAuth();
  const { validateRecord: validateProduct } = useProductValidator();

  // Update the required list items in valve accordion
  const updateRequiredItemsList = useCallback((options: object) => {
    setRequiredItems(options);
  }, []);

  //
  const updateRequiredItems = useCallback((value: string, itemKey: string) => {
    setRequiredItems((prev) => ({ ...prev, [itemKey]: value }));
  }, []);

  useEffect(() => {
    if (onOpen && shoppingCartOpen) onOpen();
  }, [onOpen, shoppingCartOpen]);

  useEffect(() => {
    fetchCart({}).then(({ data }) => {
      if (cart && cart.items.length) {
        const ids = data?.cart?.items.map((c) => c.id) || [];
        deleteCartItems({ ids }).then(() => {
          updateCartItems({
            input: cart.items.map((item) => {
              return { id: item.id, sku: item.sku, quantity: item.quantity };
            }),
          }).then(({ data }) => {
            if (data?.updateCartItems) {
              setCart(data.updateCartItems);
            }
          });
        });
      } else {
        if (data?.cart) {
          setCart(data.cart);
        }
      }
    });
    // only run on mount or when user changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCart, user]);

  // Add a CartItem
  const addCartItem = useCallback(
    (product: Product | ProductCollectionItem, quantity: number, note?: string, isMissingProductRequest?: boolean) => {
      const errors = validateProduct(product, true);

      if (Object.keys(errors).length) {
        throw new Error('ecommerce:errors.invalidProductConfiguration');
      }

      let configuration = null;
      if (product.id === 'QU01') {
        configuration = { ...product };
        delete configuration.pricing;
      }

      updateCartItems({
        input: [{ sku: product.id, quantity, configuration, note, isMissingProductRequest }],
      }).then(({ data }) => {
        if (data?.updateCartItems) {
          setCart(data.updateCartItems);
        }

        const item = data?.updateCartItems?.items.find((item) => item.sku === product.id);
        if (onItemAdded && item) {
          onItemAdded(item);
        }
      });
    },
    [updateCartItems, onItemAdded, validateProduct]
  );

  // update a CartItem
  const updateCartItem = useCallback(
    (id: string, product: Product | ProductCollectionItem, quantity: number, note?: string) => {
      updateCartItems({ input: { id, sku: product.id, quantity, note } }).then(({ data }) => {
        if (data?.updateCartItems) {
          setCart(data.updateCartItems);
        }

        const item = cart?.items.find((item) => item.id === id);
        if (onItemUpdated && item) {
          onItemUpdated(item);
        }
      });
    },
    [updateCartItems, cart?.items, onItemUpdated]
  );

  const confirmRemoveCartItems = useCallback(
    async (ids?: string[]) => {
      ids = ids || itemsToDelete.map((c) => c.id);
      const items = cart?.items.filter((item) => ids?.includes(item.id));

      await deleteCartItems({ ids }).then(({ data }) => {
        if (items && onItemRemoved) {
          items?.forEach((item) => {
            onItemRemoved(item);
          });
        }

        if (data?.deleteCartItems) {
          setCart(data.deleteCartItems);
        }

        if (onEmptyCart) {
          onEmptyCart();
        }
      });
      setItemsToDelete([]);
    },
    [deleteCartItems, itemsToDelete, onItemRemoved, onEmptyCart, cart?.items]
  );

  // Remove a CartItem by SKU
  const removeCartItems = useCallback(
    async (ids: string[], options?: { withoutConfirmation?: boolean }) => {
      const items = ids.map((id) => cart?.items?.find((c) => c.id === id));
      if (options?.withoutConfirmation) {
        await confirmRemoveCartItems(ids);
      } else {
        setItemsToDelete(items.filter(Boolean) as unknown as CartItem[]);
      }
    },
    [cart?.items, confirmRemoveCartItems]
  );

  const emptyCart = useCallback(
    async (options?: { withoutConfirmation?: boolean }) => {
      const ids = cart?.items?.map((c) => c.id) || [];
      await removeCartItems(ids, options);
    },
    [cart, removeCartItems]
  );

  const isEmpty = cart && cart?.items.length > 0 ? false : true;

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCartOpen,
        setShoppingCartOpen,
        cart,
        addCartItem,
        removeCartItems,
        updateCartItem,
        confirmOpen,
        setConfirmOpen,
        isEmpty,
        emptyCart,
        isLoading,
        isCompleted,
        error,
        updateRequiredItemsList,
        updateRequiredItems,
        requiredItems,
      }}
    >
      {children}

      {/* Essentially, this component will only be available when there are items in the cart. It will not be shown when quotation is submitted instead user will be redirected to the confirmation screen */}
      <ShoppingCart open={shoppingCartOpen} onClose={() => setShoppingCartOpen(false)} />

      <ConfirmDialog
        title={t('ecommerce:dialog.confirmDeleteCartItem.title', { defaultValue: 'Delete' })}
        subtitle={t('ecommerce:dialog.confirmDeleteCartItem.subtitle', { defaultValue: 'Cart item' })}
        active={Boolean(itemsToDelete.length)}
        onClose={() => setItemsToDelete([])}
        onConfirm={confirmRemoveCartItems}
      >
        <Typography paragraph>
          {t('ecommerce:dialog.confirmDeleteCartItem.text', {
            defaultValue: 'You are about to remove the following items from your cart:',
          })}
        </Typography>
        <PentairInfoCard>
          {itemsToDelete.map((item, index) => (
            <Box marginTop={index === 0 ? 0 : 1} key={index}>
              <CartItemDescription title={item.quantity + 'x - ' + item.title} cartItem={item} />
            </Box>
          ))}
        </PentairInfoCard>
      </ConfirmDialog>
    </ShoppingCartContext.Provider>
  );
};

/**
 * Returns the shoppingcart context
 *
 * @returns shoppingcart context
 *
 */

export const useShoppingCart = () => {
  const context = useContext(ShoppingCartContext);
  if (!context) {
    throw new Error('shopping cart Context missing');
  }
  return context;
};

export default ShoppingCartProvider;

export const PentairInfoCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(2),
}));
